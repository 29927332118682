import Vue from 'vue';

export default {
    isAuth: function (thisParam) {
        const self = thisParam;

        if (self.$route.query.auth && self.$route.query.authExpires && self.$route.query.authExpiresDate) {
            self.$cookie.set('auth', self.$route.query.auth);
            self.$cookie.set('authExpires', self.$route.query.authExpires);
            self.$cookie.set('authExpiresDate', self.$route.query.authExpiresDate);
        }

        if (self.$cookie.get('auth')) {
            if (self.$route.name == 'login') {
                self.$router.push({
                    name: 'campaignValidation',
                });
            }
        } else {
            if (self.$route.name != 'login') {
                self.$router.push({
                    name: 'login',
                });
            }
        }
    },
    isRightsOk: function (thisParam, page) {
        var self = thisParam;

        if (self.$cookie.get('rights')) {
            switch (page) {
                case 'CampaignValidation':
                    if (JSON.parse(self.$cookie.get('rights')).trading == 1 || JSON.parse(self.$cookie.get('rights')).client_service == 1 || JSON.parse(self.$cookie.get('rights')).direction == 1) {
                    } else {
                        self.$router.push({
                            name: 'campaignValidation',
                        });
                    }
                    break;
                case 'Trading':
                    if (JSON.parse(self.$cookie.get('rights')).trading != 1) {
                        self.$router.push({
                            name: 'campaignValidation',
                        });
                    }
                    break;
                case 'LinkCampaign':
                    if (JSON.parse(self.$cookie.get('rights')).trading != 1) {
                        self.$router.push({
                            name: 'campaignValidation',
                        });
                    }
                    break;
            }
        } else {
            self.$router.push({
                name: 'campaignValidation',
            });
        }
    },
    logout: function () {
        Vue.cookie.delete('auth');
        Vue.cookie.delete('authExpires');
        Vue.cookie.delete('authExpiresDate');
        window.location.href = '/login';
    },
    refreshToken: function (token, validity) {
        const expiresDate = Date.now() + validity * 1000;

        Vue.cookie.set('auth', token, {
            expires: parseInt(validity) + 's',
        });
        Vue.cookie.set('authExpires', validity, {
            expires: parseInt(validity) + 's',
        });
        Vue.cookie.set('authExpiresDate', expiresDate, {
            expires: parseInt(validity) + 's',
        });
    },
};
