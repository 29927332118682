<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
                <h2 class="brand-text text-primary ml-1">Adcleek</h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="Login V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Connexion </b-card-title>
                    <b-card-text class="mb-2"> Bienvenue, merci de saisir vos identifiants afin de vous connecter. </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- email -->
                            <b-form-group label="Email" label-for="login-email">
                                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                    <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null" name="login-email" placeholder="john@example.com" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Password</label>
                                    <b-link :to="{ name: 'auth-forgot-password-v2' }">
                                        <small>Forgot Password?</small>
                                    </b-link>
                                </div>
                                <validation-provider #default="{ errors }" name="Password" rules="required">
                                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false : null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button type="submit" variant="primary" block @click="validationForm"> Sign in </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import VuexyLogo from '@core/layouts/components/Logo.vue';
    import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue';
    import useJwt from '@/auth/jwt/useJwt';
    import { required, email } from '@validations';
    import { togglePasswordVisibility } from '@core/mixins/ui/forms';
    import store from '@/store/index';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import http from '@/helpers/http';

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BCardText,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                status: '',
                password: '',
                userEmail: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email,
            };
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
                    return this.sideImg;
                }
                return this.sideImg;
            },
        },
        methods: {
            async validationForm() {
                this.$refs.loginValidation.validate().then((success) => {
                    if (success) {
                        useJwt
                            .login({
                                login: this.userEmail,
                                password: this.password,
                            })
                            .then(async (response) => {
                                let token = response.data.token;
                                useJwt.setLoginData(token, response.data.validity, this.userEmail);
                                await this.$store.dispatch('app/getAvailableMedias');
                                http.get(process.env.VUE_APP_APIURL + '/billing/rights', token)
                                    .then(async (res) => {
                                        const right = res[0].data;
                                        useJwt.setRights(JSON.stringify(right));

                                        let filters = [];
                                        let subfilters = [];

                                        if (right && right.trading && right.trading == 1) {
                                            filters = ['7'];
                                        } else if (right && right.client_service && right.client_service == 1) {
                                            filters = ['8'];
                                            subfilters = ['csm'];
                                        } else if (right && right.direction && right.direction == 1) {
                                            filters = ['8'];
                                            subfilters = ['direction'];
                                        } else {
                                            filters = ['0'];
                                            subfilters = [];
                                        }

                                        await this.$store.dispatch('app/updateFilterStatus', filters);
                                        await this.$store.dispatch('app/updateStatusSubFilter', subfilters);

                                        this.$router.push('/');
                                        this.$toast({
                                            component: ToastificationContent,
                                            props: {
                                                title: 'Connecté',
                                                icon: 'smile',
                                                variant: 'success',
                                            },
                                        });
                                    })
                                    .catch((e) => {
                                        throw e;
                                    });
                            })
                            .catch((e) => {
                                console.error(e);
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Connection erreur',
                                        icon: 'x-circle',
                                        variant: 'danger',
                                    },
                                });
                            });
                    }
                });
            },
        },
    };
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
